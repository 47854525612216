<template>
  <el-card class="box-card">
    <div
        slot="header"
        style="
          display: flex;
          justify-content: space-between;
          padding: 0 50px 0 10px;
        "
        class="clearfix"
    >
      <h3 class="inBlock">{{ id ? '编辑福袋' : '添加福袋' }}</h3>
      <back></back>
    </div>
    <div class="container" ref="container">
      <el-row :gutter="20">
        <el-col :span="12" :offset="6">
          <el-form ref="form" :model="form" label-width="180px" class="form">
            <el-form-item
              label="福袋标题"
              :rules="{ required: true, message: '请输入标题', trigger: 'change' }"
              prop="title"
            >
              <el-input :disabled="form.status === 1" v-model="form.title" placeholder="请输入福袋标题"/>
            </el-form-item>
            <el-form-item
              label="选择直播间"
              prop="live_id"
              :rules="{ required: true, message: '请选择', trigger: 'change' }"
            >
              <el-input v-model="curLive.title" disabled><i @click="delData(1)" class="el-icon-delete" slot="append"></i></el-input>
              <el-button :disabled="form.status === 1" type="primary" size="medium" @click="openLayer(1)">选择直播间</el-button>
            </el-form-item>
            <el-form-item
              label="选择商品"
              prop="goods_id"
              :rules="{ required: true, message: '请选择', trigger: 'change' }"
            >
              <el-input v-model="curGoods.name" disabled><i @click="delData(2)" class="el-icon-delete" slot="append"></i></el-input>
              <el-button :disabled="form.status === 1" type="primary" size="medium" @click="openLayer(2)">选择商品</el-button>
            </el-form-item>
            <el-form-item
              label="奖品数量"
              :rules="{ type: 'number', message: '请选择', trigger: 'change' }"
              prop="number"
            >
              <el-input :disabled="form.status === 1" v-model.number="form.number" placeholder="请输入商品数量" @input="changeNum">
                <span slot="append">个</span>
              </el-input>
            </el-form-item>
            <el-form-item
              label="评论内容"
            >
              <el-input :disabled="form.status === 1" v-model="form.comment" placeholder="请输入评论内容"/>
            </el-form-item>
            <el-form-item
              label="中奖人员"
              :rules="{ required: true, message: '请选择', trigger: 'change' }"
              prop="is_real"
            >
              <el-radio :disabled="form.status === 1" v-model="form.is_real" :label="2" @change="changeStatus">虚拟用户</el-radio>
              <el-radio :disabled="form.status === 1" v-model="form.is_real" :label="1" @change="changeStatus">真实用户</el-radio>
            </el-form-item>

            <template v-for="(item, index) in form.user">
              <el-form-item
                label="虚拟中奖头像"
                :rules="{ required: true, message: '请上传头像', trigger: 'change' }"
                :prop="'user.' + index + '.user_avatar'"
              >
                <template v-if="form.user">
                  <div v-if="form.user[index].user_avatar" class="image" :key="index">
                    <img :src="imgurl+form.user[index].user_avatar" alt="" />
                    <template v-if="form.status !== 1">
                      <span class="shou" @click="delMinimg(index)">×</span>
                    </template>
                  </div>
                  <div v-else @click="setIndex(index)" :key="index">
                    <template v-if="form.status === 1">
                      <up-img :type="'user'"></up-img>
                    </template>
                    <template v-else>
                      <up-img @addimg="upMinimg" :type="'user'"></up-img>
                    </template>
                  </div>
                </template>

                <template v-else>
                  <div @click="setIndex(index)" :key="index">
                    <template v-if="form.status === 1">
                      <up-img :type="'user'"></up-img>
                    </template>
                    <template v-else>
                      <up-img @addimg="upMinimg" :type="'user'"></up-img>
                    </template>
                  </div>
                </template>
              </el-form-item>

              <el-form-item
                label="虚拟中奖昵称"
                :rules="{ required: true, message: '请输入昵称', trigger: 'change' }"
                :prop="'user.' + index + '.username'"
              >
                <el-input :disabled="form.status === 1" v-model="form.user[index].username" placeholder="请输入昵称"/>
              </el-form-item>
            </template>
            <el-form-item>
              <el-button :disabled="form.status === 1" type="primary" @click="submitForm('form')">立即创建</el-button>
              <el-button :disabled="form.status === 1" @click="resetForm('form')">重置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <!-- 选择直播间 -->
    <el-dialog :title="layerTitle" :visible.sync="dialogTableVisible" :before-close="closeLayer">
      <el-row :gutter="20">
        <el-col :span="10">
          <el-input placeholder="直播间名称" v-model="filterForm.title">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="getTableData">搜索</el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table :data="tableData" border :max-height="500">
        <template v-for="(item,index) in tableCoulmn">
          <el-table-column
            v-if="item.prop == 'status'"
            :prop="item.prop"
            :label="item.label"
            :key="index"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.status==1">未开播</template>
              <template v-else-if="scope.row.status==2">直播中</template>
              <template v-else-if="scope.row.status==3">已关闭</template>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :prop="item.prop"
            :label="item.label"
            :key="index"
          />
        </template>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="small" @click="checkData(scope.row)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="SetPage"
        :current-page="filterForm.page"
        :page-size="filterForm.limit"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-dialog>
  </el-card>
</template>
<script>
import UpImg from "@/components/upImg.vue";
import { getSystemsettings } from "../../api/getSystemsetting.js";
import {
  getGoodsListData,
  getLiveListData,
  addBag,
  getBagData
} from "@/api/luckyBag";

export default {
  name: "addLuckyBag",
  components: {
    UpImg
  },
  data() {
    return {
      // 容器宽高
      containerHeight: null,
      containerWidth: null,
      // 表单数据
      form: {
        title: '',
        live_id: '',
        goods_id: '',
        is_real: '',
        user: []
      },
      // oss前缀
      imgurl: '',
      // 当前选中图片上传器下标
      curIndex: null,
      // 表格弹窗布尔值
      dialogTableVisible: false,
      // 表格数据
      tableData: [],
      // 表头
      tableCoulmn: [],
      // 弹窗类型  1直播间  2商品
      layerType: 1,
      // 弹窗标题
      layerTitle: '',
      // 直播间表头
      liveColumn: [
        {
          label: '直播名称',
          prop: 'title',
          width: 250,
        },
        {
          label: '主播姓名',
          prop: 'username',
          width: 250,
        },
        {
          label: '直播分类',
          prop: 'cate_name',
          width: 250,
        },
        {
          label: '直播状态',
          prop: 'status',
          width: 250,
        },
      ],
      // 商品表头
      goodsColumn: [
        {
          label: '商品id',
          prop: 'id',
          width: 250,
        },
        {
          label: '商品名称',
          prop: 'name',
          width: 250,
        },
        {
          label: '价格',
          prop: 'price',
          width: 250,
        },
        {
          label: '商品库存',
          prop: 'goods_num',
          width: 250,
        },
      ],
      // 筛选表单
      filterForm: {
        limit: 10,
        page: 1
      },
      total: null,
      // 当前被选中的直播
      curLive: {},
      // 当前被选中的商品
      curGoods: {}
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  methods: {
    // 上传图片
    upMinimg(data) {
      this.form.user[this.curIndex].user_avatar = data
    },
    /**
     * 删除图片
     * @param index   下标
     */
    delMinimg(index) {
      this.form.user[index].user_avatar = ''
    },
    /**
     * 设置当前点击的图片上传器
     * @param index
     */
    setIndex(index) {
      this.curIndex = index
    },
    /**
     * 奖品数量更改
     * @param val
     */
    changeNum(val) {
      if (this.form.is_real == 2) {
        val = val * 1
        let len = this.form.user ? this.form.user.length : 0
        if (len > val) {
          this.form.user.splice(val, len - val)
        } else {
          for (let i=0; i < val - len ;i++) {
            let data = { user_avatar: '', username: '' }
            this.form.user.push(data)
          }
        }
      } else if (this.form.is_real == 1) {
        this.form.user = []
      }
    },
    /**
     * 切换用户类型
     */
    changeStatus() {
      if (this.form.is_real == 2){
        let val = this.form.number * 1
        let len = this.form.user ? this.form.user.length : 0
        if (len > val){
          this.form.user.splice(val, len - val)
        } else {
          for (let i=0; i < val - len ;i++) {
            let data = { user_avatar: '', username: '' }
            this.form.user.push(data)
          }
        }
      } else {
        this.form.user = []
      }
    },
    /**
     * 提交表单
     * @param formName
     */
    submitForm(formName) {
      this.form.id = this.id ? this.id : null
      let that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addBag(that.form).then(res=>{
            console.log(res);
            if (res.data.code==200){
              that.$message.success('添加成功')
              that.$router.go(-1)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    /**
     * 重置表单
     * @param formName
     */
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /**
     * 打开表格弹窗
     * @param tp
     */
    openLayer(tp) {
      this.layerType = tp
      if (tp==1){
        this.layerTitle = '直播间列表'
        this.tableCoulmn = this.liveColumn
      } else if (tp==2){
        this.layerTitle = '商品列表'
        this.tableCoulmn = this.goodsColumn
      }
      this.getTableData()
    },
    /**
     * 分页器
     * @param e
     */
    SetPage(e) {
      this.filterForm.page = e
      this.getTableData()
    },
    /**
     * 获取表格数据
     */
    async getTableData() {
      let data;
      if (this.layerType == 2){
        data = await getGoodsListData(this.filterForm)
      } else {
        data = await getLiveListData(this.filterForm)
      }
      this.total = data.data.data.total
      this.tableData = data.data.data.data
      this.dialogTableVisible = true
    },
    /**
     * 选择数据
     * @param row   当前行数据
     */
    checkData(row) {
      if (this.layerType == 1){
        this.form.live_id = row.id
        this.curLive = row
      } else if (this.layerType == 2){
        this.form.goods_id = row.id
        this.curGoods = row
      }
      this.closeLayer()
    },
    /**
     * 关闭列表弹窗
     */
    closeLayer() {
      this.dialogTableVisible = false
      this.filterForm = {
        page: 1,
        limit: 10,
        title: null
      }
    },
    /**
     * 删除已选数据
     * @param tp  类型 1、直播数据   2、商品数据
     */
    delData(tp) {
      if (tp == 1){
        this.form.live_id = null
        this.curLive = {}
      } else if (tp == 2){
        this.form.goods_id = null
        this.curGoods = {}
      }
    },
    /**
     * 获取福袋详情
     */
    async getBagInfo() {
      let { data } = await getBagData({id:this.id})
      this.form = data.data
      this.curGoods.name = data.data.goods_name
      this.curLive.title = data.data.live_name
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(this.id);
    if (this.id){
      this.getBagInfo()
    }
    getSystemsettings.then((res) => {
      console.log(res)
      this.imgurl = res;
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.containerHeight = this.$refs['container'].offsetHeight
    console.log(this.containerHeight);
    this.containerWidth = this.$refs['container'].offsetWidth
  },
  //生命周期 - 创建之前
  beforeCreate() {
  },
  //生命周期 - 挂载之前
  beforeMount() {
  },
  //生命周期 - 更新之前
  beforeUpdate() {
  },
  //生命周期 - 更新之后
  updated() {
  },
  //生命周期 - 销毁之前
  beforeDestroy() {
  },
  //生命周期 - 销毁完成
  destroyed() {
  },
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {
  },
}
</script>
<style scoped lang="less">
/deep/.el-card__body {
  height: 100%;
}
.container {
  padding: 20px;
  width: 100%;
  .form {
    padding-bottom: 30px
  }
  .image {
    font-size: 12px;
    width: 130px;
    height: 150px;
    color: #aaa;
    border: 1px solid #eeeeee;
    position: relative;
    top: -10px;
    margin: 10px 10px 0 0;

    img {
      width: 130px;
      height: 150px;
    }

    span {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      height: 20px;
      background-color: #ffd1d1;
      color: #fff3f3;
      z-index: 9;

      &:hover {
        background-color: #ff4544;
        color: #ffffff;
      }
    }
  }
}
/deep/.el-row {
  margin-bottom: 20px;
}
</style>
